import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    let inputRadio = document.querySelector('.btn.setup-card-btn input[type=radio]:checked')
    if(!inputRadio) {
      inputRadio = document.querySelector('.btn.setup-card-btn input[type=radio]')
      inputRadio.setAttribute("checked", "checked")
    }
    inputRadio.parentElement.classList.add("selected")
  }

  updatePosition(event){
    event.preventDefault();
    event.stopPropagation();

    let inputRadio = document.querySelector('.btn.setup-card-btn input[type=radio]:checked')
    if(inputRadio) {
      inputRadio.removeAttribute("checked")
      inputRadio.parentElement.classList.remove("selected")
    }
    let button = event.currentTarget
    button.classList.add("selected");
    button.querySelector("input[type=radio]").setAttribute("checked", "checked");
  }

}
