import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.aiEnabled  = this.data.get("aiEnabled");
    this.isValidated  = this.data.get("validated");
    this.actionName = this.data.get("actionName");

    if (this.shouldValidateBlast()) {
      this.validateBlast();
    }
  }

  validateBlast() {
    toastr.error("You must validate your blast before you can send it");
  }

  shouldValidateBlast() {
    return this.aiEnabled == 'true' && this.actionName === "step_4" && this.isValidated == 'false';
  }

  reviewStep(event) {
    const reviewAiBlast = document.getElementById("review-ai-blast");
    const reviewBlast = document.getElementById("review-blast");
    const reviewBlastStatus = document.getElementById("review-blast-status");

    if (reviewBlast && reviewAiBlast && reviewBlastStatus) {
      reviewBlast.style.display = 'block'
      reviewBlastStatus.style.display = 'block'
      reviewAiBlast.style.display = 'none'
    }

    event.currentTarget.style.display = 'none';
  }

  formTrigger(event){
    if (this.aiEnabled) {
      $("#new-generating-modal").modal()
    }

    const refUrl = event.currentTarget.getAttribute('data-ref-url')
    jQuery("form.edit_blast #ref").val(refUrl)
    jQuery('form.edit_blast').submit();
  }

  step3FomTrigger(event){
    const refUrl = event.currentTarget.getAttribute('data-ref-url')
    jQuery("form.edit_blast #ref").val(refUrl)
    window.bee.save()
  }

  setBlastTigger(event){
    let blast_id = $("#blast_id").attr("data-params")
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    $.ajax({
      type: "POST",
      url: "/app/" + $("body").attr("data-account-id") + "/blast/update_trigger",
      data: {id: blast_id, filters: params_array},
    });
    this.formTrigger(event)
    //$('form.edit_interaction #ref').val($(this).data('ref-url'));
    //$('form.edit_interaction').submit();
  }

}
